import React, { useState, useEffect } from "react";
import "./Home.css";
import { db } from "../../Firebase";
import SingleBlog from "../../components/singleblog/SingleBlog";
import { getDocs, collection, orderBy, query, addDoc, Timestamp, doc, onSnapshot } from "firebase/firestore";

function Home() {
    const [blogLists, setBlogLists] = useState([]);
    const docRef = collection(db, "blogs");

    //  realtime reading data
    useEffect(() => {
        const data = query(docRef, orderBy("author", "desc"));
        onSnapshot(data, (querysnapshot) => {
            setBlogLists(
                querysnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }))
            );
        });
    }, []);

    // useEffect(() => {
    //     const dataFetcher = async () => {
    //         const data = await getDocs(query(docRef, orderBy("timestamp", "desc")));

    //         setBlogLists(
    //             data.docs.map((doc) => ({
    //                 ...doc.data(),
    //                 id: doc.id,
    //             }))
    //         );
    //     };

    //     dataFetcher();
    // }, []);

    return (
        <div className="homepage">
            <div className="header">
                <span>List of Blogs:</span>
            </div>

            <div className="blogCard">
                {blogLists.map((blog) => {
                    return <SingleBlog blog={blog} key={blog.id} />;
                })}
            </div>
        </div>
    );
}

export default Home;
