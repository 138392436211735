import React, { useState, createContext } from "react";

export const AuthStatus = createContext();

function ContextApi({ children }) {
    const [authData, setAuthData] = useState(localStorage.getItem("authdata"));
    // const [authData, setAuthData] = useState(localStorage.getItem("authdata"));

    return <AuthStatus.Provider value={{ authData, setAuthData }}>{children}</AuthStatus.Provider>;
}

export default ContextApi;
