import React from "react";
import "./App.css";
import Navbar from "./components/navabar/Navbar";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import CreateBlog from "./pages/createblog/CreateBlog";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Elo from "./pages/login/Elo";

function App() {
    return (
        <div>
            <BrowserRouter>
                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="login" element={<Login />} />
                    <Route path="createblog" element={<CreateBlog />} />
                    <Route path="*" element={<h1> 404 Page Not Found</h1>} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
