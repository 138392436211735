// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAiFII1O-8_K3CtvcN36mMjK8XFt1CjDB8",
    authDomain: "blog-25d7f.firebaseapp.com",
    projectId: "blog-25d7f",
    storageBucket: "blog-25d7f.appspot.com",
    messagingSenderId: "905212817849",
    appId: "1:905212817849:web:dcd6be6d2b310f66cc0668",
    measurementId: "G-CPYL36BBFX",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
