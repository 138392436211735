import React from "react";
import "./CreateBlog.css";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { db, auth } from "../../Firebase";
import { AuthStatus } from "../../context/ContextApi";

const CreateBlog = () => {
    const navigate = useNavigate();
    const { authData } = useContext(AuthStatus);
    const [title, setTitle] = useState("");
    const [description, setDesciption] = useState("");

    const docRed = collection(db, "blogs");

    const addBlog = async (title, blog) => {
        try {
            await addDoc(docRed, {
                title: title,
                description: description,
                author: {
                    name: auth.currentUser.displayName,
                    id: auth.currentUser.uid,
                    image: auth.currentUser.photoURL,
                },
                timestamp: Timestamp.now(),
            });

            navigate("/");
        } catch (err) {
            alert(err);
        }
    };

    return (
        <div className="createblog">
            <span className="title">Create Blog</span>

            <div className="input">
                <div className="inputtitle">
                    <label>Title:</label>
                    <input type="text" value={title} placeholder="Title" onChange={(e) => setTitle(e.target.value)} />
                </div>

                <div className="inputdec">
                    <label>Description :</label>
                    <textarea
                        value={description}
                        placeholder="Description"
                        onChange={(e) => setDesciption(e.target.value)}
                    />
                </div>
                <button onClick={() => addBlog(title, description)} className="createbtn">
                    Create
                </button>
            </div>
        </div>
    );
};

export default CreateBlog;
