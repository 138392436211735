import "./Navabar.css";
import React, { useContext } from "react";
import { AuthStatus } from "../../context/ContextApi";
import { Link, useNavigate } from "react-router-dom";
import { auth } from "../../Firebase";
import { signOut } from "firebase/auth";

const Navbar = () => {
    const navigate = useNavigate();
    const { authData, setAuthData } = useContext(AuthStatus);
    const signoutUser = () => {
        signOut(auth).then(() => {
            // localStorage.clear();
            localStorage.removeItem("authdata");
            setAuthData(false);
        });

        navigate("/login");
    };
    return (
        <div className="navbar">
            <Link to="/">
                <span className="logo">Blogs</span>
            </Link>

            <div className="pages">
                {!authData ? (
                    <Link to="/login">
                        <span className="page">Login</span>
                    </Link>
                ) : (
                    <>
                        <Link to="/">
                            <span className="page">Home</span>
                        </Link>
                        <Link to="/createblog">
                            <span className="page">Create Blog</span>
                        </Link>

                        <span className="page" onClick={signoutUser}>
                            Logout
                        </span>
                    </>
                )}
            </div>
        </div>
    );
};
export default Navbar;
