import React, { useContext } from "react";
import "./Login.css";
import { AuthStatus } from "../../context/ContextApi";
import { auth, provider } from "../../Firebase";
import { signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";

function Login() {
    const navigate = useNavigate();
    const { setAuthData } = useContext(AuthStatus);

    const signinwithgoogle = () => {
        signInWithPopup(auth, provider).then((result) => {
            setAuthData(true);
            localStorage.setItem("authdata", true);
            navigate("/");
        });
    };
    return (
        <div className="loginpage">
            <div>
                <span>Sign in with google</span>
                <span> to continue</span>
            </div>

            <button onClick={signinwithgoogle} className="googlebtn img-size">
                sign in with google
            </button>
        </div>
    );
}

export default Login;
