import React, { useContext, useEffect } from "react";
import "./SingleBlog.css";
import { db, auth } from "../../Firebase";
import { AuthStatus } from "../../context/ContextApi";
import { deleteDoc, doc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

function SingleBlog({ blog }) {
    const navigate = useNavigate();
    const { authData } = useContext(AuthStatus);

    const deleteVlog = (id) => {
        const taskDocRef = doc(db, "blogs", id);
        try {
            deleteDoc(taskDocRef);
        } catch (error) {
            alert("cant  delete this file");
        }
    };

    useEffect(() => {
        if (!authData) {
            navigate("/login");
        }
    }, [authData]);

    return (
        <div className="blog">
            {authData && blog.author.id === auth.currentUser.uid ? (
                <div>
                    <div className="blogheader">
                        <div className="userdata">
                            <img className="profileimage" src={blog?.author.image} alt="" />
                            <div className="usertextdata">
                                <p className="authorname">@{blog?.author?.name}</p>
                                {blog.timestamp ? (
                                    <p className="timestamp">{blog?.timestamp.toDate().toDateString()}</p>
                                ) : null}
                            </div>
                        </div>
                        <div className="title">
                            <span className="blogTitle">{blog.title}</span>
                        </div>

                        <div className="deleteblog">
                            {authData && blog.author.id === auth.currentUser.uid && (
                                <button onClick={() => deleteVlog(blog.id)}>x</button>
                            )}
                        </div>
                    </div>
                    <div className="desc">{blog.description}</div>
                </div>
            ) : null}
        </div>
    );
}

export default SingleBlog;
